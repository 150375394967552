import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import DataTable from "@/components/DataTable/DataTable";
import { formatNumber } from "@/utils/numbers";
function findExtremumProperty(arr, key, type = "max", idProperty = "p_id") {
  if (!Array.isArray(arr) || arr.length === 0) {
    throw new Error("Input must be a non-empty array");
  }
  if (typeof key !== "string" || typeof idProperty !== "string") {
    throw new Error("Key and idProperty must be strings");
  }
  if (!["max", "min"].includes(type)) {
    throw new Error('Type must be either "max" or "min"');
  }
  const extremeIdx = arr.reduce((extremeIdx, obj, idx) => {
    if (type === "max") {
      return +obj[key] > +arr[extremeIdx][key] ? idx : extremeIdx;
    } else {
      return +obj[key] < +arr[extremeIdx][key] ? idx : extremeIdx;
    }
  }, 0);
  return arr[extremeIdx][idProperty];
}
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  emits: ["updateDynamic"],
  data() {
    return {
      bestSoldId: null,
      bestRevenueId: null,
      bestPriceId: null,
      bestPrice_sppId: null,
      bestPrice_oldId: null,
      bestReviewsId: null,
      bestDiscountId: null,
      bestRemainsId: null,
      bestRatingId: null,
      bestCategoriesId: null,
      bestKeywordsId: null,
      bestAvg_soldId: null,
      bestAvg_revenueId: null,
      bestDaysId: null,
      bestLossesId: null,
      bestP_volumeId: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      try {
        const data = await this.$store.dispatch("product/getCompareProducts", {
          ...query,
          ...this.reportSettings
        });
        if (data.items.length) {
          this.$emit("updateDynamic", data.items);
        }
        data.items[0].bordered = true;
        this.bestSoldId = findExtremumProperty(data.items, "sold");
        this.bestRevenueId = findExtremumProperty(data.items, "revenue");
        this.bestPriceId = findExtremumProperty(data.items, "price", "min");
        this.bestPrice_sppId = findExtremumProperty(data.items, "price_spp", "min");
        this.bestPrice_oldId = findExtremumProperty(data.items, "price_old", "min");
        this.bestReviewsId = findExtremumProperty(data.items, "reviews");
        this.bestDiscountId = findExtremumProperty(data.items, "discount");
        this.bestRemainsId = findExtremumProperty(data.items, "remains");
        this.bestRatingId = findExtremumProperty(data.items, "rating");
        this.bestCategoriesId = findExtremumProperty(data.items, "categories");
        this.bestKeywordsId = findExtremumProperty(data.items, "keywords");
        this.bestAvg_soldId = findExtremumProperty(data.items, "avg_sold");
        this.bestAvg_revenueId = findExtremumProperty(data.items, "avg_revenue");
        this.bestDaysId = findExtremumProperty(data.items, "days");
        this.bestLossesId = findExtremumProperty(data.items, "losses", "min");
        this.bestP_volumeId = findExtremumProperty(data.items, "p_volume");
        data.items.forEach(item => {
          Object.keys(item).forEach(key => {
            if (!["p_id", "sku"].includes(key)) {
              item[key] = formatNumber(item[key]);
            }
          });
        });
        return data;
      } catch (e) {
        const errorMessage = e.message;
        if (typeof errorMessage === "string" && errorMessage !== "no-limit-error") {
          this.$emit("limitReached", errorMessage);
        }
      }
    }
  },
  computed: {
    columns() {
      const svg = '<svg id="Layer_1" style="enable-background:new 0 0 120 120; position:absolute;top:0;right:-25px" version="1.1" width="20" height="20" viewBox="0 0 120 120" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">.st0{fill:#FFC54D;}.st1{fill:#C19D72;}.st2{fill:#A88763;}</style><g><path class="st0" d="M101,34l-0.2-1.7h-10c0.5-3.4,0.8-6.9,1-10.5c0.1-1.9-1.4-3.5-3.1-3.5H31.4c-1.8,0-3.2,1.6-3.1,3.5   c0.1,3.6,0.5,7.1,1,10.5h-10L19,34c-0.1,0.4-1.2,10.6,5.4,19.8c4.3,6,11,10.1,19.7,12.2c2.8,2.8,5.9,4.9,9.2,6.2   c-0.4,4.1-0.9,8.1-1.4,11.8h16.3c-0.6-3.8-1.1-7.7-1.5-11.8c3.3-1.2,6.4-3.3,9.2-6.2c8.7-2.1,15.4-6.2,19.7-12.2   C102.2,44.6,101,34.4,101,34z M27.3,51.3c-4.2-5.8-4.7-12.1-4.7-15.1h7.3c1.9,9.5,5.3,17.9,9.6,24.2C34.3,58.4,30.2,55.3,27.3,51.3   z M92.7,51.3c-2.9,4-7,7.1-12.2,9.1c4.4-6.4,7.7-14.7,9.6-24.2h7.3C97.4,39.2,96.8,45.5,92.7,51.3z"/><path class="st1" d="M77,98.1H43c-1,0-1.8-0.8-1.8-1.8V83.5c0-1,0.8-1.8,1.8-1.8h34c1,0,1.8,0.8,1.8,1.8v12.8   C78.8,97.3,78,98.1,77,98.1z"/><path class="st2" d="M37.9,101.9h44.2c1,0,1.8-0.8,1.8-1.8v-3.8c0-1-0.8-1.8-1.8-1.8H37.9c-1,0-1.8,0.8-1.8,1.8v3.8   C36.1,101,36.9,101.9,37.9,101.9z"/><path class="st0" d="M68,91H52c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h16c0.7,0,1.2,0.5,1.2,1.2v2.5   C69.2,90.5,68.6,91,68,91z"/></g></svg>';
      let a = [{
        title: "Товар",
        show: true,
        name: "title",
        width: 500,
        filter: "text",
        type: "link",
        noOverflow: true,
        getImage: item => item.image,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                mp: this.reportSettings.mp,
                p_id: item.p_id
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.title,
            target: "_blank",
            slotRight: item.p_id === this.reportSettings.sku[0] ? '<span class="accent-on-dark" style="padding: 5px 10px; border-radius: 4px; color:white; font: 500 10px Ubuntu; background-color:#316d92; position: relative; top: -8px; margin-left: 10px">Ваш товар</span>' : ""
          };
        }
      }, {
        title: "SKU",
        show: true,
        name: "sku",
        width: 100,
        filter: "text",
        type: "link",
        getLink: item => {
          return {
            href: item.mp_url,
            text: item.sku
          };
        }
      }, {
        title: `Продажи (шт)`,
        show: true,
        name: "sold",
        type: "number",
        width: 180,
        filter: "numberRange",
        getText: (item, itemRow) => {
          if (itemRow.p_id == this.bestSoldId) {
            return `<div style="position: relative">${item} ${svg}</div>`;
          }
          return item;
        }
      }, {
        title: `Выручка`,
        show: true,
        name: "revenue",
        type: "money",
        width: 180,
        filter: "numberRange",
        getAdditionalInfo: (item, itemRow) => {
          if (item) {
            if (itemRow.p_id == this.bestRevenueId) {
              return `<div style="position: relative">${item} ₽ ${svg}</div>`;
            }
            return item + " ₽";
          }
        }
      }];
      if (this.reportSettings.mp == "wb") {
        a = a.concat([{
          title: "Цена с WB кошельком",
          show: true,
          name: "price",
          type: "money",
          width: 120,
          filter: "numberRange",
          getAdditionalInfo: (item, itemRow) => {
            if (item) {
              if (itemRow.p_id == this.bestPriceId) {
                return `<div style="position: relative">${item} ₽ ${svg}</div>`;
              }
              return item + " ₽";
            }
          }
        }, {
          title: "Цена",
          show: true,
          name: "price_spp",
          type: "money",
          width: 120,
          filter: "numberRange",
          getAdditionalInfo: (item, itemRow) => {
            if (item) {
              if (itemRow.p_id == this.bestPrice_sppId) {
                return `<div style="position: relative">${item} ₽ ${svg}</div>`;
              }
              return item + " ₽";
            }
          }
        }, {
          title: "Скидка",
          show: true,
          name: "discount",
          type: "number",
          width: 100,
          filter: "numberRange",
          getText: (item, itemRow) => {
            if (itemRow.p_id == this.bestDiscountId) {
              return `<div style="position: relative">${item} % ${svg}</div>`;
            }
            return item + " %";
          }
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 120,
          filter: "numberRange",
          getAdditionalInfo: (item, itemRow) => {
            if (item) {
              if (itemRow.p_id == this.bestPrice_oldId) {
                return `<div style="position: relative">${item} ₽ ${svg}</div>`;
              }
              return item + " ₽";
            }
          }
        }]);
      } else {
        a = a.concat([{
          title: "Цена",
          show: true,
          name: "price",
          type: "money",
          width: 120,
          filter: "numberRange",
          getAdditionalInfo: (item, itemRow) => {
            if (item) {
              if (itemRow.p_id == this.bestPriceId) {
                return `<div style="position: relative">${item} ₽ ${svg}</div>`;
              }
              return item + " ₽";
            }
          }
        }, {
          title: "Старая цена",
          show: true,
          name: "price_old",
          type: "money",
          width: 120,
          filter: "numberRange",
          getAdditionalInfo: (item, itemRow) => {
            if (item) {
              if (itemRow.p_id == this.bestPrice_oldId) {
                return `<div style="position: relative">${item} ₽ ${svg}</div>`;
              }
              return item + " ₽";
            }
          }
        }, {
          title: "Скидка",
          show: true,
          name: "discount",
          type: "number",
          width: 100,
          filter: "numberRange",
          getText: (item, itemRow) => {
            if (itemRow.p_id == this.bestDiscountId) {
              return `<div style="position: relative">${item} % ${svg}</div>`;
            }
            return item + " %";
          }
        }]);
      }
      a = a.concat([{
        title: "Текущий остаток",
        show: true,
        name: "remains",
        type: "number",
        width: 120,
        filter: "numberRange",
        getText: (item, itemRow) => {
          if (itemRow.p_id == this.bestRemainsId) {
            return `<div style="position: relative">${item} ${svg}</div>`;
          }
          return item;
        }
      }, {
        title: "Отзывы",
        show: true,
        name: "reviews",
        type: "number",
        width: 100,
        filter: "numberRange",
        getText: (item, itemRow) => {
          if (itemRow.p_id == this.bestReviewsId && item) {
            return `<div style="position: relative">${item} ${svg}</div>`;
          }
          return item;
        }
      }, {
        title: "Рейтинг",
        show: true,
        name: "rating",
        type: "number",
        width: 100,
        filter: "numberRange",
        getText: (item, itemRow) => {
          if (itemRow.p_id == this.bestRatingId && item) {
            return `<div style="position: relative">${item} ${svg}</div>`;
          }
          return item;
        }
      }, {
        title: "Кол-во категорий",
        show: true,
        name: "categories",
        type: "number",
        width: 100,
        filter: "numberRange",
        getText: (item, itemRow) => {
          if (itemRow.p_id == this.bestCategoriesId && item) {
            return `<div style="position: relative">${item} ${svg}</div>`;
          }
          return item;
        }
      }, {
        title: "Поисковые запросы",
        show: true,
        name: "keywords",
        width: 100,
        filter: "numberRange",
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "ProductInfo",
              params: {
                mp: this.reportSettings.mp,
                p_id: item.p_id,
                tab: "keywordsPositions"
              },
              query: {
                date: this.reportSettings.date,
                date2: this.reportSettings.date2,
                fbs: this.reportSettings.fbs
              }
            },
            text: item.keywords,
            target: "_blank"
          };
        },
        getAdditionalInfo: (item, itemRow) => {
          if (item) {
            if (itemRow.p_id == this.bestKeywordsId) {
              return `<div style="position: relative">${item} ${svg}</div>`;
            }
            return item;
          }
        }
      }, {
        title: `Сред. продаж в день (шт)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 180,
        filter: "numberRange",
        getText: (item, itemRow) => {
          if (itemRow.p_id == this.bestAvg_soldId && item) {
            return `<div style="position: relative">${item} ${svg}</div>`;
          }
          return item;
        }
      }, {
        title: `Сред. выручка в день`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 180,
        filter: "numberRange",
        getAdditionalInfo: (item, itemRow) => {
          if (item) {
            if (itemRow.p_id == this.bestAvg_revenueId) {
              return `<div style="position: relative">${item} ₽ ${svg}</div>`;
            }
            return item + " ₽";
          }
        }
      }, {
        title: `Дней в наличии`,
        show: true,
        name: "days",
        type: "number",
        width: 180,
        filter: "numberRange",
        getText: (item, itemRow) => {
          if (itemRow.p_id == this.bestDaysId && item) {
            return `<div style="position: relative">${item} ${svg}</div>`;
          }
          return item;
        }
      }, {
        title: `Упущенная выручка`,
        show: true,
        name: "losses",
        type: "money",
        width: 180,
        filter: "numberRange",
        getAdditionalInfo: (item, itemRow) => {
          if (itemRow.p_id == this.bestLossesId) {
            return `<div style="position: relative">${item} ₽ ${svg}</div>`;
          }
          return item + " ₽";
        }
      },
      // {
      //     title: "Объем, л",
      //     show: true,
      //     name: "p_volume",
      //     type: "number",
      //     width: 100,
      //     getText: (item, itemRow) => {
      //         if (itemRow.p_id == this.bestP_volumeId) {
      //             return `<div style="position: relative">${item} ${svg}</div>`;
      //         }
      //         return item;
      //     },
      // },

      {
        title: "Впервые",
        show: true,
        name: "date",
        width: 140,
        filter: "dateRange",
        type: "date"
      }, {
        title: "Последний раз",
        show: true,
        name: "date2",
        width: 140,
        filter: "dateRange",
        type: "date"
      }]);
      return a.map(item => {
        item.sort = false;
        return item;
      });
    }
  }
};